<template lang="pug">
v-container(fluid)
  v-row(justify="center", align="center")
    div(style="width: 1000px")
      v-flex#executaveis-table(md12)
        h2.ma-2 Versões disponíveis
      v-card.ma-2(elevation-3)
        v-data-table(
          :headers="headers",
          :items="items",
          hide-default-footer,
          disable-pagination,
          :no-data-text="'Nenhuma versão encontrada.'"
        )
          template(v-slot:item.versao="{ item }")
            router-link(:to="`/releases/${item['versao']}`", tag="a") {{ item["versao"] }}
</template>

<script>
import { mapMutations } from "vuex";
import { versoes as api } from "@/api";

export default {
  name: "releasesVersao",
  data: () => ({
    loaded: false,
    headers: [
      { value: "versao", text: "Selecione uma versão:", sortable: false },
    ],
    items: [],
  }),
  methods: {
    ...mapMutations("loader", ["showLoader", "hideLoader"]),
    async getDados() {
      this.showLoader("Carregando versões...");
      this.loaded = false;
      try {
        let data = await api.getExecutaveisExternal();
        data = this.ordenarPorVersao(data);
        this.items = data.filter((item) => !item["versao"].endsWith(".0"));
      } catch (error) {
        console.error(error);
      } finally {
        this.loaded = true;
        this.hideLoader();
      }
    },

    ordenarPorVersao(lista) {
      return lista.sort((a, b) => {
        const versaoA = a.versao.split(".").map(Number);
        const versaoB = b.versao.split(".").map(Number);

        for (let i = 0; i < Math.max(versaoA.length, versaoB.length); i++) {
          const numA = versaoA[i] || 0;
          const numB = versaoB[i] || 0;

          if (numA > numB) return -1; // Mais atual
          if (numA < numB) return 1; // Mais antiga
        }

        return 0; // Se todas as partes forem iguais
      });
    },
  },
  mounted() {
    this.getDados();
  },
};
</script>